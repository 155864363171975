<template>
  <div>
    <el-dialog
      title="设置"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      top="0"
      width="664px"
      @close="close"
    >
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/15.png" />
        <div class="perImg flex">
          <div class="perImg_left">
            <img v-if="params.avatar" :src="params.avatar" />
          </div>
          <div class="perImg_right flex_direction_column">
            <span>个人头像</span>
            <div class="desc">
              <p>尺寸不限，JPG / GIF / PNG，</p>
              <p>RGB模式，3M以内。</p>
            </div>
            <div class="btn">
              上传图片
              <input type="file" class="upload-img-file" @change="upload($event)" />
            </div>
          </div>
        </div>
        <div class="form">
          <el-form :model="params" :rules="rules" ref="ruleForm" label-width="52px">
            <el-form-item label="昵称" prop="nick_name">
              <el-input placeholder="请输入姓名" v-model="params.nick_name"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btn flex">
        <span @click="saveClick">保存</span>
        <span @click="dialogVisible = false">取消</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editInfo } from '@/service/api/user'
import { ossUpload } from '@/utils/tools'
export default {
  props: {
    isSetUp: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      params: {
        avatar: this.$store.state.userInfo.avatar || '',
        nick_name: this.$store.state.userInfo.nick_name || ''
      },
      rules: {
        nick_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }]
      }
    }
  },

  mounted() {},

  methods: {
    // 修改信息
    async editInfo() {
      const res = await editInfo(this.params)
      if (res.code == 1) {
        this.$store.state.userInfo.nick_name = this.params.nick_name
        this.$store.state.userInfo.avatar = this.params.avatar
        this.dialogVisible = false
        localStorage.setItem('user-info', JSON.stringify(this.$store.state.userInfo))
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    upload(e) {
      let timestamp = new Date().getTime()
      var file = e.currentTarget.files[0]
      let fileType = file.name.substring(file.name.lastIndexOf('.')) || '.jpg'
      ossUpload('pc/' + `${timestamp}${fileType}`, file).then((res) => {
        this.params.avatar = res
      })
    },
    close() {
      this.$emit('close', false)
    },
    saveClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 修改信息
          this.editInfo()
        } else {
          return false
        }
      })
    }
  },
  watch: {
    isSetUp(val) {
      this.dialogVisible = val
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog--center .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__title {
  height: 50px;
  line-height: 50px;
  color: @color_six;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_16;
  background-color: #eeeeee;
}
/deep/ .dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/deep/ .el-form-item__label {
  text-align: left;
}
/deep/ .el-input .el-input__inner:focus {
  border-color: #dddddd !important;
}

/deep/ .el-input__inner {
  width: 433px;
}
.form {
  padding: 0px 100px 41px 100px;
}
.close {
  top: 18px;
  right: 22px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
}
.dialog_content {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px dashed #eee;
  .perImg {
    align-items: flex-start;
    padding: 49px 100px 41px 100px;
    .perImg_left {
      width: 240px;
      height: 240px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('~@/assets/image/img/35.png');
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .perImg_right {
      margin-left: 24px;
      align-items: flex-start;
      span {
        height: 25px;
        font-weight: bold;
        line-height: 25px;
        color: @color_sixteen;
        font-size: @font_size_18;
      }
      .desc {
        width: 200px;
        color: #aaaaaa;
        margin-top: 10px;
        p {
          line-height: 20px;
          font-size: @font_size_14;
        }
      }
      .btn {
        width: 142px;
        height: 44px;
        cursor: pointer;
        margin-top: 21px;
        position: relative;
        text-align: center;
        line-height: 44px;
        border-radius: 3px;
        color: @color_sixteen;
        font-size: @font_size_16;
        border: 1px solid #cccccc;
        .upload-img-file {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          width: 100%;
          height: 100%;
          margin: auto;
          position: absolute;
        }
      }
    }
  }
}
.btn {
  margin-top: 31px;
  margin-bottom: 33px;
  span {
    width: 114px;
    height: 36px;
    cursor: pointer;
    line-height: 36px;
    text-align: center;
    border-radius: 3px;
    font-size: @font_size_16;
    &:nth-child(1) {
      color: #fff;
      margin-left: 178px;
      margin-right: 80px;
      background-color: @color_one;
    }
    &:nth-child(2) {
      background-color: @color_bg_two;
    }
  }
}
</style>
